<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="12"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 liga_form,
                 data.name,
                 file1,
                 data.slide
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR TORNEO</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="data.name"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Logo</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>
          
            <div class="row mx-0 row-two">
               
                <div class="EPF1_C1">
                    <label class="labelR color-1" for="inputR">Liga</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.name_liga}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.ligas" :key="key">
                          <b-dropdown-item @click="changeLiga(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>

                    
                  </div>
                  <div class="">
                  <label class="labelR color-1" for="inputR">TORNEO PRINCIPAL</label>
                  <b-dropdown>
                    <template #button-content>
                      <div class="row"> <span class="drop-text">{{data.slide}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeSlide('ACTIVO')">
                      ACTIVO
                    </b-dropdown-item>

                      <b-dropdown-item @click="changeSlide('INACTIVO')">
                      INACTIVO
                    </b-dropdown-item>
          
                  </b-dropdown>
                </div>
                   
               
                
                
              </div>
              
 

                 <div class="row-one mtrow imagen_torneo">
                  <div id="edit-user-img" class="Imagen_Edit " v-if="msgimg == 'success'">
                 <img :src="this.image(data.image)"  alt="imagen de usuario">
                 </div>
                 </div>

           
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>


              

              

              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El torneo se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
              
              
              
            
              
            
            
           


  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import { mapActions } from "vuex";

export default {
  name: "EditGallery",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      status: "",
      msg: "",
      msgimg: "success",
       liga_form:"",
      liga_name:"Liga",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "tournament",
    };
    this.getInfoById(payload);
     this.getLigas('ligas')
  },
  computed:{
      data() {
      return this.$store.getters["main/data"];
    },
     ligas() {
          return this.$store.getters["liga/data"];
        },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
     ...mapActions('liga', ['getLigas']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function ( id_liga, name, image, slide) {
          let id = this.$route.params.id;
          
          this.status=''
          this.msg=''

        let tournament = this.data

       id_liga== undefined ||  id_liga== ''  ||  id_liga== null  ? id_liga =tournament.id_liga : id_liga;
       id_liga = String(id_liga)

         if(image == null){
                 let data ={
                     id:id,
                     name:name,
                     id_liga: id_liga,
                     slide:slide
                 }
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'tournament', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }
           
        }else{
          if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('id',id);
                  data.append('image', image);
                  data.append('name',name);
                  data.append('slide',slide);
                  data.append('id_liga',id_liga);
                  data.append('_method', 'PUT');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'tournament', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }

            }
                

        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
  image: function(img){
        let src = this.url +'/get-tournament-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'tournament'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },
    changeLiga: function (value, name) {
     
       this.liga_form = value;
      this.data.name_liga = name;

    },

    changeSlide: function (value) {
     
       this.data.slide = value;
  

    },
  },
};
</script>
<style scoped>
  .imagen_torneo{
    margin-top: 5vw;
  }
</style>
